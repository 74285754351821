
#imgubi{
    max-width: 100%;
    height: 25vw;
    width: 100%;
}
#c1{
   // background-color: red;
    //width: auto;
    
}
#c2{
    //background-color: blue;
}

#c3{
    margin-top: 45px;
}
#tituloUbic{
    font-family: Arial;
    font-size: 18px;
    font-weight: bold;
}
#map{
    align-items: center;
    text-align: center;
    
}
#idframe{
    width: 45vw;
    height: 30vw;
}