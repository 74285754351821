#contenedor{
    height:90px;
    width: 200px; 
}

#cont_item_nav {
    background-color: #00adef;
}

#icon_cont {
    display: flex;
    align-items: center;
    justify-content: center;
}

#text_cont{
    align-items: center;
    justify-content: center;
}

#tex_2{
    font-weight: bold;
    font-size: 14px;
}
