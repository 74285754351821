$secondary-color: #5271FF;

#idsectionServicios {
    margin-top: 35px;
    text-align: center;
    #linea{
        height: 10px;
        width: 15%;
        margin: auto;
        border-radius: 5px;
        color: $secondary-color;
        background-color: $secondary-color;
        opacity: 100;
    }
}

#containerItems{
    margin-top: 40px;
}