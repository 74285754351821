$secondary-color: #5271FF;
#app{
    margin-bottom: 95px;
}

#row1 {
    display: flex;
    margin-top: 50px;
}

#columna1 {
    display: flex;
    flex-direction: column;
    align-items: center;

    #qs {
        font-size: 25px;
        font-family: Arial;
        text-align: center;
    }

    #txtqs {
        font-size: 15px;
        font-family: Arial;
        text-align: center;
        max-width: 300px;

    }
}

#columna2 {
    display: flex;
    flex-direction: column;
    align-items: center;

    #pq1 {
        font-family: Arial;
        text-align: center;
        max-width: 200px;
        margin-bottom: 30px;
        font-style: oblique;
    }

    #im1 {
        border-radius: 10px;
        max-width: 100%;
        height: 30vw;
        width: 26vw;
    }
}

#qs2 {
    font-size: 25px;
    font-family: Arial;
    line-height: 25px;
}
#txtqs1 {
    font-size: 15px;
    font-family: Arial;
    text-align: justify;
    max-width: 425px;
    font-style: oblique;

}
#block1{
    margin: 80px;
}

#sectiondr{
    display: flex;
    //justify-content: center;
    justify-content: space-around;
    width: auto;
    height: 24vw;
    background-color: $secondary-color;
    #containerDr{
        text-align: center;
        height: 18vw;
        width: 18vw;
        position: relative;
        top: -80px;
    }
    
}