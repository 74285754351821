$secondary-color: #5271FF;

#footer{
    margin-bottom: 45px;
}

#sectiondr{
    display: flex;
    //justify-content: center;
    justify-content: space-around;
    width: auto;
    height: 24vw;
    background-color: $secondary-color;
    #containerDr{
        text-align: center;
        height: 18vw;
        width: 18vw;
        position: relative;
        top: -80px;
    }
    
}
#linksfooter{
    display: flex;
    justify-content: space-around;
    margin-top: 45px;
}
#aref{
    text-decoration: none;
    color: #000000;
}
#title{
    font-family: Arial;
    font-size: 28px;
    font-weight: bold;
}
#title2{
    font-family: Arial;
    font-size: 17px;
    color: $secondary-color;
}
