$secondary-color: #5271FF;
#itemServicio{
    display: flex;
    flex-direction: column;
    max-width:100%;///
    height: 16vw;
    width: 18vw;
    margin: 5px;
    justify-content: center;
    align-items: center;
    border: solid;
    border-width: 1px;
    border-color:$secondary-color;
    border-radius: 10px;
    
    color: #000000;
    font-family: Arial;
    font-size: 1.5vw;
    font-weight: bold;
}
#containerItem{
    display: flex;
    justify-content: center;
    
}

#image{
    max-width:40%;
    height: auto;
    width: auto\9;
    margin-bottom: 2px;
    margin-top: 12px;
}

#nav_link{
    text-decoration: none;
}