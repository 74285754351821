#imgdr{
    max-height: 25%;
    height: 17vw;
    width: 13vw;
}
#nameandocupation{
    margin-top: 18px;
    font-size: 15px;
    line-height: 14px;
    color: #ffffff;
}