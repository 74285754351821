#titlesQS{
    text-align: center;
    font-size: 25px;
    font-weight: bold;
}
#parrafos{
    width: 35vw;
    text-align: center;
}
#containerQS{
    display: flex;
    align-items: center;
    flex-direction: column;
}