#imgserv {
    max-width: 100%;
    height: 25vw;
    width: auto;
}

#titulos {
    font-family: Arial;
    text-align: justify;
    font-size: 35px;
}

//secciones
#secmedicina {
    margin-top: 20px;
}

#odontologia {
    margin-top: 20px;
}

#mecanicadental {
    margin-top: 20px;
}

#obstreticia {
    margin-top: 20px;
}

#psicologia {
    margin-top: 20px;
}

#servadicionales {
    margin-top: 20px;
}