#calendar{
    align-items: center;
    text-align: center;
}
#calendarframe{
    width: 45vw;
    height: 40vw;
}
#text1{
    width: 35vw;
    text-align: justify;
}